// ===============================================================
// SERIALIZE ARRAY
// ===============================================================
export function serializeArray(form) {

  const formData = new FormData(form);
  const data = {};

  for (const [name, value] of formData) {
    data[name] = value
  }

  const formBody = [];

  for (const key in data) {
    const encodeKey = encodeURIComponent(key)
    const encodeValue = encodeURIComponent(data[key])
    formBody.push(`${encodeKey}=${encodeValue}`)
  }

  return formBody = formBody.join('&')

}

// ===============================================================
// Busca Preço de Produtos por Ajax
// ===============================================================
export function getPriceProd() {
  const attr = 'data-update-price';
  const processedAttr = 'data-price-processed';
  const products = document.querySelectorAll(`[${attr}]`);

  if (products.length > 0) products.forEach(product => {
    const processed = product.getAttribute(processedAttr);

    if (processed != 'true') {
      const prodId = product.getAttribute(attr);
      const url = `/produto/preco/${prodId}`;

      if (prodId != null && prodId != '') {
        $.ajax({
          url: url,
          type: 'GET',
        })
        .done(function (resp) {
          // console.info(resp);
          product.innerHTML = resp;
          product.setAttribute(processedAttr, true);
        })
        .fail(function (resp) {
          // console.error(`Falha ao buscar o preço do produto ${prodId}`);
          // console.log(resp);
        });
      }
    }
  })
};

// ===============================================================
// DEBOUNCE
// ===============================================================
/*
  Debounce retorna uma função que enquanto continuar sendo chamada não é executada
  A função só será executada quando para de ser chamada por N milisegundos
  Útil para melhorar a performance de códigos que são executados muitas vezes por segundo, como o $(window).resize()

  Ex:
  
  $(window).resize(debounce(function() {
    // código a ser executado
  }, 500))
  
  No exemplo acima a função só será executada 500ms depois do último resize
  Abra o link abaixo e redimensione a janela branca e acompanhe o output do console
  Exemplo codepen: https://codepen.io/valkervieira/pen/oNgqyWY

  Um caso comum de uso é em lojas onde a seleção de um filtro na página de tag recarrega automáticamente a página
  Com o debounce o usuário pode escolher vários filtros rapidamente e a página só recarrega quando parar de escolher
*/

export function debounce(func, wait, immediate) {
  var timeout;
  immediate || (immediate = true);

  return function () {
    var context = this,
      args = arguments;

    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    var callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
};

// ===============================================================
// THROTTLE
// ===============================================================
/*
  Throttle diminui a frequencia que uma função é executada
  Enquanto no debounce a função só é executada quando para de ser chamada, no throttle ela
  continua sendo executada só que em um intervalo mínimo de N milisegundos (default = 250)

  Ex:

  $(window).resize(throttle() {
    // código a ser executado
  }, 500)

  No exemplo acima a função resize é chamada várias vezes por segundo mas só é executada 1 vez a cada 500ms
  Abra o link abaixo, redimensione a janela branca e acompanhe o console
  Exemplo codepen: https://codepen.io/valkervieira/pen/yLyKEPW

  Um caso comum de uso é checar se o scroll passou de um determinado ponto, para fixar um header ou alterar algum elemento do DOM
*/
export function throttle(fn, threshhold, scope) {
  threshhold || (threshhold = 250);
  var last, deferTimer;
  return function () {
    var context = scope || this;

    var now = +new Date(),
      args = arguments;
    if (last && now < last + threshhold) {
      // hold on to it
      clearTimeout(deferTimer);
      deferTimer = setTimeout(function () {
        last = now;
        fn.apply(context, args);
      }, threshhold);
    } else {
      last = now;
      fn.apply(context, args);
    }
  };
};

// ===============================================================
// FORMAT MONEY
// ===============================================================
export function formatMoney(value) {
  // FORMATA UM VALOR
  return (
    'R$ ' +
    value
      .toFixed(2)
      .replace('.', ',')
      .replace(/(\d)(?=(\d{3})+\,)/g, '$1.')
  );
};

// ===============================================================
// VALIDA QUANTIDADE
// ===============================================================
export function validateQuantity(_val) {
  // VALIDA SE A QUANTIDADE INFORMADA É UM NÚMERO
  if (!isNaN(_val)) {
    if (parseInt(_val) > 0) {
      return true;
    }
  }

  return false;
};

// ===============================================================
// CLEAR NUMBER
// ===============================================================
export function getClearNumber(_val) {
  // RETORNA UM NÚMERO LIMPO COMO INT
  if (!isNaN(_val)) {
    clearNumber = parseInt(_val);

    return clearNumber;
  }

  return false;
};

// ===============================================================
// MENSAGEM DE RESPOSTA
// ===============================================================
export function setMsgResponse(_msg, _type, _$parent) {
  /*
    O tipo pode conter um dos 3 valores: success, warning, error ou CLEAR.
    O type CLEAR limpa as mensagens e faz sumir a mensagem
    O elemento _$parent precisa ter uma div [data-msg-retorno], com uma div [data-msg] dentro,
    para exibir a mensagem corretamente no local
  */

  // Verifica se não há mais de uma mensagem
  // caso tenha, gera um html para cada mensagem
  if (Array.isArray(_msg)) {
    var msgArr = _msg;
    _msg = '';
    for (var i = msgArr.length - 1; i >= 0; i--) {
      _msg += '<span class="msg error">' + msgArr[i] + '</span>';
    }
  }

  _$parent
    .find('[data-msg-retorno]')
    .removeClass('success')
    .removeClass('warning')
    .removeClass('error');

  _$parent.find('[data-msg-retorno] [data-msg]').empty();

  if (_type != 'clear') {
    _$parent.find('[data-msg-retorno]').addClass(_type);
    _$parent.find('[data-msg-retorno] [data-msg]').html(_msg);
  }
};
