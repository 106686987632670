import Mmenu from 'mmenu-js';

const MenuMobile = {
  init: function() {

    const mmenu = new Mmenu('#menu-mobile',
      {
        // slidingSubmenus: false,
        extensions: ['pagedim-black', 'position-front'],
        navbar: {
          add: true,
        },
      },
      {
        offCanvas: {
          pageSelector: '#wrapper',
        },
      }
    )

    // document.querySelector('#menu-mobile').style.display = 'unset';
  
    $('[data-action="open-menu-mobile"]').on('click', function (event) {
      event.preventDefault();
  
      if ($('#menu-mobile').hasClass('mm-menu--opened')) {
        mmenu.close();
      } else {
        mmenu.open();
      }
    });
  }
};

export default MenuMobile
