const Search = {
  init: function() {
    var $openSearch = $('#open-search');
    var $closeSearch = $('#close-search');
    var $searchContainer = $('.search-container');

    $openSearch.click(function (e) {
      e.preventDefault();

      $searchContainer.addClass('active');
    });

    $closeSearch.click(function (e) {
      e.preventDefault();

      $searchContainer.removeClass('active');
    });

  }
}

export default Search;
