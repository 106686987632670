import MenuMobile from './common/menuMobile';
import HeaderCart from './common/headerCart';
import Popup from './components/popup';
import Webforms from './common/webforms';
import Search from './common/search';

import { setNewsletterPopup } from './components/vndaComponents';

// ==========================================
// Inicialização
// ==========================================
console.log('%cVnda - Tecnologia em Ecommerce', 'color: #f88d5b; font-size: 15px; font-family: "Verdana", sans-serif; font-weight: bold;');

window.addEventListener('DOMContentLoaded', () => {
  MenuMobile.init();
  HeaderCart.init();
  Popup.init();
  Webforms.init();
  Search.init();
  setNewsletterPopup();

  window.addEventListener("load", function(event) {
    var maxWidth = 0;
    $('[data-social-menu]').each(function(){
      var thisW = $(this).width();
      if (thisW > maxWidth) { maxWidth = thisW; }
    });
    $('[data-social-menu]').width(maxWidth);
  });

  $(".item.has-children").on("mouseover", function () {
    var position = ($(this).position()).left - 35;
    $(this).find(".submenu-container > ul").css("margin-left", position);
  });
});
