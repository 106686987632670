// ===============================================================
// CART DRAWER
// ===============================================================
export function setCartDrawer() {
  window.addEventListener('load', () => {
    // Seleciona o elemento
    const root = document.querySelector('#component-cart-drawer-root');

    if (root) {
      // Define frete grátis
      const free_shipping_value = root.getAttribute('data-free');

      // Inicia o componente
      const componentCartDrawer = new Vnda.Component.CartDrawer({
        anchor: 'right',
        display: 'list',
        startOpen: false,
        titleCart: 'Carrinho de compras',
        disableShippingCalculation: true,
        freeShipping: free_shipping_value,
      });

      // Renderiza o componente
      componentCartDrawer.render(root);

      // Adiciona o evento de abertura
      let open = document.querySelector('[data-toggle-cart]');
      if (open) open.addEventListener('click', componentCartDrawer.toggle);

      // Adiciona o componente ao escopo global
      window.componentCartDrawer = componentCartDrawer;
    }
  });
}

// ===============================================================
// NEWSLETTER POPUP
// ===============================================================
export function setNewsletterPopup() {
  window.addEventListener('load', () => {
    // Seleciona o elemento
    const root = document.querySelector('#component-popup-newsletter-root');

    if (root) {
      // Define propriedades do componente
      let maxWidth = window.data_popup.maxWidth;
      let imageUrl = window.data_popup.imageUrl;
      let title = window.data_popup.title;
      let description = window.data_popup.description;
      let subdomain = window.data_popup.subdomain;
      let successMessage = window.data_popup.success;

      // Inicia o componente
      const componentNewsletterPopup = new Vnda.Component.NewsletterPopup({
        maxWidth: maxWidth,
        maxHeight: 500,
        imageUrl: imageUrl,
        imagePosition: 'left',
        popupPosition: 'center',
        title: title,
        description: description,
        textBtnSubmit: 'Enviar',
        classBtnSubmit: 'btn-newsletter',
        formKey: `${subdomain}-newsletter`,
        hasNameField: false,
        hasLastNameField: false,
        hasDateOfBirthField: false,
        hasPhoneField: false,
        successMessage: successMessage,
        delay: 500,
        frequency: '7',
        language: 'pt-BR',
      });

      // Renderiza o componente
      componentNewsletterPopup.render(root);
    }
  });
}

// ===============================================================
// FILTROS
// ===============================================================
export function setFilters() {
  window.addEventListener('load', () => {
    var properties = [];

    var windowSize = window.outerWidth;
    var mode;
    var has_sort;
    var autoApply;
    var colorsProp;
    if (windowSize >= 992) {
      mode = 'vertical';
      has_sort = true;
      autoApply = true;
      colorsProp = {
        showTitle: false,
        showColor: true,
      }
    } else {
      mode = 'drawer';
      has_sort = false;
      autoApply = false
      colorsProp = {
        showTitle: true,
        showColor: true,
      }
    }

    // Seleciona o elemento
    const root = document.querySelector(
      '#component-products-filter-drawer-root'
    );

    const mobileRootSort = document.querySelector(
      '#component-products-filter-drawer-mobileRootSort'
    );

    if (root) {
      // Define filtros de categoria
      let tags = aggregations.types.categoria && [
        {
          title: 'Categoria',
          type: 'categoria',
          style: 'list',
          options: aggregations.types.categoria,
        },
      ];

      // Define filtros do primeiro atributo
      let property2 = aggregations.properties.property1.length > 0 && {
        title: 'Cor',
        property: 'property2',
        style: 'colors',
        colorsProps: colorsProp,
        options: aggregations.properties.property2,
      };

      if (property2) {
        properties.push(property2);
      }

      // Ordena tamanhos
      const sizesOrder = ['pp', 'p', 'm', 'g', 'gg', 'xg'];
      const sizes = aggregations.properties.property1;
      var newSizes = sizes.sort((optionA, optionB) => {
        return (
          sizesOrder.indexOf(optionA.value.toLowerCase()) -
          sizesOrder.indexOf(optionB.value.toLowerCase())
        );
      });

      newSizes = newSizes.sort((optionA, optionB) => {
        var optA = optionA.value;
        var optB = optionB.value;

        if (optionA.value.includes('/')) {
          optA = optionA.value.split('/')[0];
        }

        if (optionB.value.includes('/')) {
          optB = optionB.value.split('/')[0];
        }

        if (!isNaN(optA) && !isNaN(optB)) {
          return optA - optB;
        }
      });

      // Define filtros do segundo atributo
      let property1 = aggregations.properties.property1.length > 0 && {
        title: 'Tamanho',
        property: 'property1',
        style: 'list',
        options: newSizes,
      };

      if (property1) {
        properties.push(property1);
      }

      // Inicia o componente
      const componentFilters = new Vnda.Component.ProductsFilter({
        mode: mode,
        hasSort: false,
        filterOnClick: autoApply,
        resetMode: 'all',
        // tags type "categoria"
        tags: tags,
        // properties
        properties: properties,
        // price
        price: [aggregations.min_price, aggregations.max_price],
      });

      // Renderiza o componente
      componentFilters.render(root);

      // Adiciona evento de abertura
      var openMobile = document.getElementById('open-filter-mobile');
      var closeMobile = document.getElementById('close-filter-mobile');
      if (mode == 'drawer' && componentFilters) {
        openMobile.addEventListener('click', componentFilters.toggle);
        closeMobile.addEventListener('click', componentFilters.toggle);

        $(openMobile).on('click', ()=>{
          $('#component-products-filter-drawer-root').addClass('active')
          $('.close-filter-mobile').addClass('active')
        })
    
        $('#close-filter-mobile').on('click', ()=>{
          $('#component-products-filter-drawer-root').removeClass('active')
          $('.close-filter-mobile').removeClass('active')
        })
      }

      let open = document.querySelector('.open-filter');
      let filterBlock = document.getElementById('component-products-filter-drawer-root')
      if (open) open.addEventListener('click', function(){
        if (filterBlock.classList.contains('open')){
          filterBlock.classList.remove('open')  
          open.classList.remove('active')
        }else{
          filterBlock.classList.add('open')
          open.classList.add('active')
        }
      });
    }
    if (mobileRootSort){
      const componentSortMobile = new Vnda.Component.ProductsFilter({
        mode: 'drawer',
        hasSort: true,
        filterOnClick: autoApply,
        resetMode: 'all',
        sideDrawer: 'left',
      });
      componentSortMobile.render(mobileRootSort);
      var openMobileSort = document.getElementById('open-mobile-sort');
      var closeMobileSort = document.getElementById('close-filter-mobile-sort');
      if (componentSortMobile) {
        openMobileSort.addEventListener('click', componentSortMobile.toggle);
        closeMobileSort.addEventListener('click', componentSortMobile.toggle);
        $(openMobileSort).on('click', ()=>{
          $('#component-products-filter-drawer-mobileRootSort').addClass('active')
          $(closeMobileSort).addClass('active')
        })
    
        $(closeMobileSort).on('click', ()=>{
          $('#component-products-filter-drawer-mobileRootSort').removeClass('active')
          $(closeMobileSort).removeClass('active')
        })
      }
    }
  });
}
// ===============================================================
// LOGIN
// ===============================================================
export function setLogin() {
  window.addEventListener('load', () => {
    // Seleciona o elemento
    const root = document.querySelector('#component-login-static-root');

    if (root) {
      // Define link para o Facebook
      let facebook_connect_url = root.getAttribute('data-facebook');

      // Inicia o componente
      window.componentLogin = {};
      window.componentLogin['static'] = new Vnda.Component.Login({
        mode: 'static',
        formProps: {
          login: {
            buttons: {
              facebook: {
                link: facebook_connect_url,
              },
            },
            hasFacebook: false,
          },
          register: {
            buttons: {
              facebook: {
                link: facebook_connect_url,
              },
            },
            hasFacebook: false,
          },
        },
      });

      // Renderiza o componente
      componentLogin['static'].render(root);
    }
  });
}
