const Popup = {
  closePopup: function (_popup) {
    $('[data-popup="' + _popup + '"]').removeClass('active');
  },
  togglePopup: function (_popup) {
    $('.active[data-popup]').removeClass('active');

    if (_popup != '') {
      if ($('.active[data-popup]').length > 0) {
        setTimeout(function () {
          $('[data-popup="' + _popup + '"]').addClass('active');
        }, 350);
      } else {
        $('[data-popup="' + _popup + '"]').addClass('active');
      }
    }
  },
  init: function () {
    var _this = this;
    $(document).on('click', '[data-action="open-popup"]', function (event) {
      event.preventDefault();
      //console.info('event open-opopup');
      var popup = $(this).data('popup');
      _this.togglePopup(popup);
    });

    $(document).on('click', '[data-action="close-popup"]', function (event) {
      event.preventDefault();
      //console.info('event close-opopup');
      var popup = $(this).data('popup');
      _this.closePopup(popup);
    });
  },
};

export default Popup
